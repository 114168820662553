/** Site header **/
.site-header {
  float: left;
  // Positioning context for the mobile navigation icon
  // position: relative;
}

.site-title {
  font-size: calc(3.2em + 1vw);
  text-transform: uppercase;
  font-weight: 300;
  line-height: 56px;
  letter-spacing: -1px;
  color: $accent-color;
  display: block;
  a, &:visited {
    color: $accent-color;
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.contact {
  margin: $spacing-unit 0;
  list-style: none;
  width: 150px;
  display: block;
  a {
    color: $grey-color;
  }
  &:visited {
    color: $grey-color;
  }
}

.left-sidebar {
  list-style: none;
  margin: $spacing-unit 0;
  display: block;
}

.site-nav {
  line-height: 56px;

  .menu-icon {
    display: none;
  }
  .page-link {
    color: $accent-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg path {
        fill: $grey-color-dark;
      }
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}


/** Page content **/
.page-content {
  margin-top: 80px;
  margin-left: 20%;
}

.page-heading {
  font-size: 20px;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  font-size: 24px;
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    font-size: 36px;
  }
}

@include media-query($on-palm) {
  font-size: 24px;
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    font-size: 32px;

    @include media-query($on-laptop) {
      font-size: 28px;
    }
  }
  @include media-query($on-palm) {
  font-size: 22px;
}

  h3 {
    font-size: 26px;

    @include media-query($on-laptop) {
      font-size: 22px;
    }
  }

  h4 {
    font-size: 20px;

    @include media-query($on-laptop) {
      font-size: 18px;
    }
  }
}


@include media-query($on-palm) {
  .contact {
    margin-top: 5px;
  }
  .left-sidebar {
    margin: 0;

    > li {
      display: inline-block;
      padding: 0;
    }
  }
  .site-header {
    margin: 0;
    clear: both;
    display: block;
  }
  .page-content {
    margin: 140px 0 0 0;
  }
  .icon {
    padding: 0;
  }
}

.flexslider {

  margin: 0;
  padding: 0;

  .slides {

    > li {

      display: none;
      -webkit-backface-visibility: hidden;

    }

    img{

      width: 100%;
      display: block;

    }

    &:after{

      content: "\0020";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;


    }

    html[xmlns] &{

      display: block;

    }

    * html &{

      height: 1%;

    }

  }
}

.flex-direction-nav {
  list-style: none;

  li {
    display: inline;
  }

  li:last-child {
    text-align: right;
    float: right;
  }
}

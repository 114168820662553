// Define defaults for each variable.

$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       rgb(74,74,73) !default;
$background-color: #fdfdfd !default;
$accent-color:     rgb(250,84,86) !default;

$grey-color:       rgb(74,74,73) !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import
  "base",
  "grid",
  "layout",
  "syntax-highlighting"
;

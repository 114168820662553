ul.grid {
  display: grid;
  grid-gap: 12px;
  margin-left: 0 !important;
  margin-top: 2em;
  grid-template-columns: 1fr 1fr 1fr;
  
  @include media-query($on-palm) {
    grid-template-columns: 1fr;
  }

  li {
    background-color: $grey-color-light;
    border-top: 1px $accent-color solid;
    list-style: none;
    padding: 12px;

    a {
      font-size: $small-font-size;
    }
  }
  .title {
    font-size: $base-font-size;
    color: $grey-color-dark;
  }

  .notes {
    display: none;
  }
}

